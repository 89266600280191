.App {
  margin-top: 15px;
  margin-bottom: 15px;
}

.Header {
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
  padding-top: 5px;
}

.Header img {
  max-width: 200px;
}

.loadingContainer img {
  margin: 0 auto;
  display: block;
}

tr.noTopRowBorder td {
  padding-bottom:20px !important;
}

tr:first-child.noTopRowBorder td {
  border: none !important;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 19%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 19%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 19%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}