@media all and (min-width: 480px) {
    .Signup {
      padding: 10% 0;
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }

    .Signup img {
      margin: 0 auto;
      padding-bottom: 50px;
      max-width: 320px;
      display: block;
    } 

    .contactAdminAlert {
      margin: 0 auto;
      max-width: 320px;
      margin-bottom: 20px;
      text-align: center;
    }

    .confirmStep {
      margin: 0 auto;
      max-width: 320px;
    }
  }

  .signupError {
    margin: 0 auto;
    max-width: 320px;
  }
  
  .Signup form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
  }
  
