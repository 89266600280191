body {
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: calc(100vw - 34px);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 14px;
}

input[type=file] {
  width: 100%;
}


.hr-sect {
	display: flex;
	flex-basis: 100%;
	align-items: center;
	color: rgba(0, 0, 0, 0.35);
  margin: 8px 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.hr-sect::before,
.hr-sect::after {
	content: "";
	flex-grow: 1;
	background: rgba(0, 0, 0, 0.35);
	height: 1px;
	font-size: 0px;
	line-height: 0px;
	margin: 0px 8px;
}

.table-hover tbody tr:hover > td {
  cursor: pointer;
}

.col-hidden { 
    display: none 
}


