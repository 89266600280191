.form-group-sm .searchField {
    height: 38px;
    font-size: 16px;
    margin-bottom: 20px;
}

.search {
    float: right;
    margin-top: 60px;
    width: 200px;
    height: 40px;
}

.adminButtons  {
    float: right;
    margin-top: 60px;
}

.react-bs-table-csv-btn {
    float: right !important;
}

.pagination {
    margin: 0;
    float: right;
}

.btn-toolbar {
    margin-bottom:20px;
}

.btn-group.btn-group-sm {
    padding-top: 10px;
}

.clientPages {
    float: right;
    margin-bottom: 0px !important;
}

.react-bs-table-csv-btn {
    color: aquamarine !important;
}