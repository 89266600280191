.Home .lander {
    text-align: center;
}
  
.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}
  
.Home .lander p {
    color: #999;
}

.problemMonitors h4 p {
    font-weight: bold;
    color: #8a6d3b !important;
}

.problemMonitors .table>tbody>tr>td {
    border:none;
}

.downMonitors h4 p {
    font-weight: bold;
    color: #772121 !important;
}

.downMonitors .table>tbody>tr>td {
    border:none;
}

.maintenance {
    padding-bottom: 20px;
}

.maintenance h4 {
    font-weight: bold;
}

.maintenance .table>tbody>tr>td {
    border:none;
}

