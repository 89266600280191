.NewClient form {
    margin: 0 auto;
    margin-top:30px;
    margin-bottom:30px;
}

.NewClient .LoaderButton {
    width: 150px;   
}

.fullWidth {
    width:100%
}

.NewClient .panel {
    margin-top: 25px;
}