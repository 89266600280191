@media all and (min-width: 480px) {
    .Login {
      padding: 10% 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }

    .Login img {
      margin: 0 auto;
      padding-bottom: 50px;
      max-width: 320px;
      display: block;
    } 

    .Login button {
      margin-top:20px;
    }
}
  