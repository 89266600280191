.panel {
    margin-bottom: 15px;
}

.container {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}

.instanceContainer {
    display:flex; 
    flex-direction:row;
}

.instancePanel {
    width:33%;
    margin: 5px;
}

.col-sm-4 {
    padding-left:0px;
}

.rag {
    float: right;
    margin-right: 30px;
    text-align: center;
    margin-bottom: 0px;
}

.address {
    font-size: 12px;
}

.about {
    margin-top:10px;
    font-size: 12px;
    font-style: italic;
}

.instances {
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
}

.adminButton  {
    float: right;
    margin-bottom: 10px;
}

.clientName {
    padding-bottom: 10px;
    padding-left: 5px;
}

.nav-tabs {
    margin-bottom: 10px;
}

.attachments {
    font-size: 20px;
    color: #8c8484;
    float: right;
    text-align: center;
    padding: 15px 0;
    width: 100%;
}

.addButton  {
    float: right;
    margin-top: 20px;
}

.note {
    width: 100%;
}

.footnote {
    font-size: 12px;
    font-style: italic;
    white-space: normal;
}

.noteModal {
    padding-right: 0px;
    padding-left: 0px;
    margin: auto;
    padding-top:150px;
}

.modal-open {
    padding-right: 15px !important;
}

.modalBody {
    display: inline-block;
    vertical-align: top;
    width: 70%;
    white-space: pre-line;
    word-wrap: break-word;
}

.modalAttachments {
    display: inline-block;
    width: 30%;
    padding-left: 20px;
    text-align: left;
}

.modalAttachmentList {
    padding-top: 25px;
}

.modalTitle {
    width: 70%;
    margin-bottom:0px;
}

.modalBodyTextArea {
    width: 100%;
    height: 300px;
}

.modalFooter {
    float: left;
    padding-top: 7px;
}

.form-group-lg select[multiple].form-control, .form-group-lg textarea.form-control {
    height: 100%;
}

.hide {
    display: none
}

.trashLink {
    color: rgb(101, 159, 245); 
    cursor: pointer;
}

.trashLink:hover {
    text-decoration: underline;
}

.inactive {
    background-color: #fddbda;
    padding-left: 5px;
}

.zendesk .form-group-sm .searchField {
    height: 30px;
    font-size: 14px;
    margin-bottom: 5px;
}

.tooltip .tooltip-inner {
    max-width: 500px !important;
    padding: 10px;
}

.multilineColumn {
    white-space: pre !important;
}

.zendeskOverlay {
    padding-left: 5px;
}

.table-test {
    background-color: #d9edf7;
    color: #31708f;
}

.table-app {
    background-color: #dff0d8;
    color: #3c763d;
}

.table-staging {
    background-color: #fcf8e3;
    color: #8a6d3b;
}

.Stats {
    margin-top:40px;
}

.right {
    text-align: right;
    padding-right: 20px;
}